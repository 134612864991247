import DateUtil from 'Utils/dateUtil'; 

export default [
    {
        type: 'selection',
        width: 38,
        align: 'center'
    },
    { 
        title: 'Applied On',
        key: 'AppliedOn',
        sortable: true,
        minWidth: 90,
    },
	{
        title: 'Leave Type',
        key: 'LeaveType',
		sortable: true,
        minWidth: 85,
    },
    {
        title: 'Reason',
        key: 'Reason',
        minWidth: 80,
        width: 500,
    },
    {
        title: 'Leave Date Start',
        key: 'LeaveDateStart',
        sortable: true,
        minWidth: 90,
    },
    {
        title: 'Leave Date End',
        key: 'LeaveDateEnd',
        sortable: true,
        minWidth: 90,
        //Changes by Mahima on 21st July 2023 Changed the date format to yyyy-mm-dd
        // render: (h, params) => {
        //     return h('span', params.row.LeaveDateEnd ? DateUtil.formatDate(params.row.LeaveDateEnd) : '');
        // },
    },
    {
        title: 'Status',
        key: 'LeaveStatus',
        minWidth: 80,
    }
]
