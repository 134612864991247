<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Leave" slot="title" link="/helpContent/StaffLeaveList" />
    <ValidationObserver ref="validator">
      <FormRow>
          <div class="col-sm-2">
            <FormSelect
              label="Select Staff"
              :items="staffList"
              item-name="Name"
              item-value="id"
              v-model="filtering.selectedStaff"
            />
          </div>
          <div class="col-sm-3">
              <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
                  <Icon type="ios-search" />
              </FormButton>
          </div>
      </FormRow>
    </ValidationObserver>
    <DataTable
      :actions="true"
      :data="staffLeaveList"
      :columns="tableConfig"
      :is-loading="isLoading"
      @on-selection-change="selectChange"
      @on-page-change="pageChange"
      :pagination="pagination"
      @on-sort-change="sortChange"
    >
      <template slot="actionsLeft">
        <TableButton @click="() => add()">
          <img :src="require(`Assets/images/plus-1.png`)" />
          Add
        </TableButton>
        <!-- <TableButton @click="() => edit()">
          <img :src="require(`Assets/images/edit-1.png`)" />
          Edit
        </TableButton> -->
        <TableButton @click="() => remove()">
          <img :src="require(`Assets/images/del.png`)" />
          Remove
        </TableButton>
      </template>
    </DataTable>
    <Popup
      title="Information"
      :value="warningModal.visible"
      @close="() => showWarningModal(false)"
    >
      {{ warningModal.message }}
      <div slot="footer">
        <FormButton
          v-if="warningModal.buttons.cancel"
          @click="() => showWarningModal(false)"
          >Cancel
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.confirm"
          type="success"
          @click="confirmDelete"
          >Confirm
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.ok"
          @click="() => showWarningModal(false)"
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
  import FormWrapper from "Components/form/FormWrapper";
  import PageTitle from "Components/layout/PageTitle";
  import DataTable from "Components/DataTable";
  import loadingMixin from "Mixins/loadingMixin";
  import { getAllStaffByHRorManager, leaveList, removeLeave } from "../api";
  import tableConfig from "./table";
  import validateSelectedRows from "Mixins/validateSelectedRows";
  import { ValidationObserver } from "vee-validate";
  export default {
    name: "List",
    mixins: [loadingMixin, validateSelectedRows],
    components: {
      FormWrapper,
      DataTable,
      PageTitle,
      ValidationObserver,
    },
    data() {
      return {
        filtering: {
          statuses: [],
          statusSelected: "",
          selectedStaff: "",
          dates: "",
        },
        sorting: {
          key: "id",
          order: "asc",
        },
        pagination: {
          perPage: 10,
          page: 1,
          total: 0,
        },
        tableConfig,
        staffLeaveList: [],
        selectedRows: [],
        showMessage: {
          isVisible: false,
          message: "",
        },
        staffList: [],
      };
    },
    computed: {
      userData() {
        return this.$store.state.user.user;
      },
    },
    watch: {},
    created() {
      this.getStaff();
    },
    methods: {
      add() {
        this.$router.push({ path: "/applyForLeave" });
      },
      remove() {
        if (this.validateSelectedLength(1)) {
          if (this.deleteSelectedValue()) {
          }
        }
      },
       async confirmDelete() {
        this.showWarningModal(false);
        const data = {
          id: this.selectedRows[0].id,
        };
      await removeLeave(data).then((res) => {
          if (res.data[0].status > 1) {
            this.showWarningModal(true);
            this.warningModal.buttons.ok = true;
            this.warningModal.buttons.cancel = false;
            this.warningModal.buttons.confirm = false;
            this.warningModal.message = res.data[0].message;
            this.selectedRows = [];
          } else {
            this.selectedRows = [];
            this.warningModal.buttons.ok = true;
            this.warningModal.buttons.cancel = false;
            this.warningModal.buttons.confirm = false;
            this.showMessage.isVisible = true;
            this.showMessage.message = res.data[0].message;
          }
        });
        setTimeout(() => {
          this.getDataByFilter();
        }, 50);
       
      },
      edit() {
        if (this.validateSelectedLength(1)) {
          this.$router.push({
            path: `/applyForLeave/edit/${btoa(this.selectedRows[0].id)}`,
          });
        }
      },
     async getStaff() {
       await getAllStaffByHRorManager().then((res) => {
          this.staffList = res.data;
          this.filtering.selectedStaff = this.userData.user_id;
        });
        setTimeout(() => {
          this.getDataByFilter();
        }, 500);
      },
      search() {
        this.pagination.page = 1;
        this.getDataByFilter();
      },
      selectChange(data) {
        this.selectedRows = data;
      },
      sortChange(data) {
        this.sorting.key = data.key;
        this.sorting.order = data.order;
        this.getDataByFilter();
      },
      getDataByFilter() {
        setTimeout(() => {
          this.$refs.validator.validate().then((result) => {
            if (result) {
              this.showLoader();
              const { key, order } = this.sorting;
              const { perPage, page } = this.pagination;

              const filters = {};
              filters.staffId = this.filtering.selectedStaff;

              const data = {
                filterjson: {
                  filter: [filters],
                  sort: [{ key, order }],
                  paging: [
                    {
                      startIndex: page,
                      pageSize: perPage,
                    },
                  ],
                },
              };
              leaveList(data)
                .then(this.handleResponse)
                .catch(this.handleError);
            }
          });
        }, 50);
      },
      pageChange(page) {
        this.pagination.page = page;
        this.getDataByFilter();
      },
      handleResponse(response) {
        this.hideLoader();
        this.staffLeaveList = response.data;
        this.pagination.total = response.NumberOfRecords;
      },
    },
  };
</script>

<style lang="scss" module>
  .popupRow {
    padding: 5px 0 15px;
  }
  .pagination {
    margin: 10px 0 0;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    line-height: 26px;
  }

  .paginationText {
    margin: 0 20px;
  }

  .paginationButton {
    cursor: pointer;
    width: 26px;
    text-align: center;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
      box-shadow 200ms ease;
  }
  .paginationButton:hover {
    background: #57a3f3;
    color: #fff;
    &:hover {
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
        0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .filter {
    margin: -10px 0 10px;
  }

  .filterItem {
    display: inline-block;
    margin: 0 15px 15px 0;
  }

  .filterItemCheckbox {
    flex-basis: auto;
    align-self: flex-start;
    margin-top: 13px;
  }
</style>
